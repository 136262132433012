<template>
  <div class="energyMall-box">
    <div class="box">
      <div class="top">
        <div class="title">剩余能量数</div>
        <div class="num">50</div>
      </div>
      <div class="bottom">
        <div class="item" @click="toGrandTotal">
          <div class="num">50</div>
          <div class="title">
            历史累计能量
            <img src="../../../assets/images/MoreIocn.png" alt="">
          </div>
        </div>
        <div class="item" @click="toExpire">
          <div class="num">50</div>
          <div class="title">
            即将到期能量
            <img src="../../../assets/images/MoreIocn.png" alt="">
          </div>
        </div>
        <div class="item" @click="toConsume">
          <div class="num">50</div>
          <div class="title">
            消耗能量
            <img src="../../../assets/images/MoreIocn.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="travel">
      <div class="top-title">
        <img src="../../../assets/images/travelImg.png" alt="">
        <span>旅游产品</span>
        <img src="../../../assets/images/travelImg.png" alt="">
      </div>
      <div class="travel-box">
        <div class="item" @click="toDetails">
          <div class="top">
            <img src="../../../assets/images/rong.png" alt="">
          </div>
          <div class="bottom">
            <div class="title">探秘缅甸4晚5日 佛国朝探秘缅甸4晚5日 佛国朝</div>
            <div class="total">探秘缅甸4晚5日 佛国朝探秘缅甸4晚5日 佛国朝</div>
            <div class="num">500能量</div>
          </div>
        </div>
        <div class="item">
          <div class="top">
            <img src="../../../assets/images/rong.png" alt="">
          </div>
          <div class="bottom">
            <div class="title">探秘缅甸4晚5日 佛国朝探秘缅甸4晚5日 佛国朝</div>
            <div class="total">探秘缅甸4晚5日 佛国朝探秘缅甸4晚5日 佛国朝</div>
            <div class="num">500能量</div>
          </div>
        </div>
        <div class="item">
          <div class="top">
            <img src="../../../assets/images/rong.png" alt="">
          </div>
          <div class="bottom">
            <div class="title">探秘缅甸4晚5日 佛国朝探秘缅甸4晚5日 佛国朝</div>
            <div class="total">探秘缅甸4晚5日 佛国朝探秘缅甸4晚5日 佛国朝</div>
            <div class="num">500能量</div>
          </div>
        </div>
      </div>
    </div>

    <div class="life">
      <div class="top-title">
        <img src="../../../assets/images/travelImg.png" alt="">
        <span>生活服务</span>
        <img src="../../../assets/images/travelImg.png" alt="">
      </div>
      <div class="life-box">
        <div class="item">
          <div class="top">
            <img src="../../../assets/images/rong.png" alt="">
          </div>
          <div class="bottom">
            <div class="title">探秘缅甸4晚5日 佛国朝探秘缅甸4晚5日 佛国朝</div>
            <div class="total">
              <div>探秘缅甸4晚5日 佛国朝探秘缅甸4晚5日 佛国朝</div>
              <div class="num">500能量</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="top">
            <img src="../../../assets/images/rong.png" alt="">
          </div>
          <div class="bottom">
            <div class="title">货拉拉优惠券</div>
            <div class="total">
              <div>货拉拉优惠券</div>
              <div class="num">66能量</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="top">
            <img src="../../../assets/images/rong.png" alt="">
          </div>
          <div class="bottom">
            <div class="title">探秘缅甸4晚5日</div>
            <div class="total">
              <div>探秘缅甸4晚5日</div>
              <div class="num">500能量</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {
    toGrandTotal() {
      console.log('历史累计能量')
      this.$router.push({ path: '/grandTotal' })
    },
    toExpire() {
      console.log('即将到期能量')
      this.$router.push({ path: '/expire' })
    },
    toConsume() {
      console.log('消耗能量')
      this.$router.push({ path: '/consume' })
    },
    toDetails() {
      console.log('去详情')
      this.$router.push({ path: '/details' })
    }
  }
}
</script>
<style scoped>
#app {
  background: #f8f8f8 !important;
}
.box {
  background: red;
  width: 100%;
  height: 130px;
  position: relative;
  z-index: 0;
}
.box .top {
  text-align: center;
  color: #fff;
  z-index: 1;
}
.box .top .title {
  padding-top: 30px;
  font-size: 12px;
}
.box .top .num {
  font-size: 25px;
  padding-top: 15px;
  font-weight: 600;
}
.box .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #333;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 10px;
  right: 10px;
  bottom: -40px;
}

.box .bottom .item .num {
  color: red;
}
.box .bottom .item .title {
  padding-top: 5px;
  color: #333;
  font-size: 12px;
}
.box .bottom .item .title img {
  display: inline-block;
  width: 10px;
  height: 10px;
  padding-left: 5px;
}
.travel {
  padding: 60px 10px 0;
  text-align: center;
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.travel .top-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.travel .top-title span {
  padding-right: 10px;
  padding-left: 10px;
}
.travel .top-title img {
  width: 15px;
  height: 15px;
}
.travel .travel-box {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.travel .travel-box .item {
  margin-top: 10px;
  background: #fff;
  width: 49%;
  border-radius: 10px;
}
.travel .travel-box .item .top img {
  width: 100%;
  height: 130px;
  border-radius: 10px 10px 0 0;
}
.travel .travel-box .item .bottom {
  padding: 10px;
  font-size: 12px;
  color: #333;
}
.travel .travel-box .item .bottom .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.travel .travel-box .item .bottom .total {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  padding-top: 6px;
}
.travel .travel-box .item .bottom .num {
  color: red;
  padding-top: 6px;
  font-weight: normal;
  text-align: left;
}

.life {
  padding: 20px 10px 60px;
  text-align: none !important;
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.life .top-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.life .top-title span {
  padding-right: 10px;
  padding-left: 10px;
}
.life .top-title img {
  width: 15px;
  height: 15px;
}
.life .life-box {
  padding-top: 10px;
}

.life .life-box .item {
  margin-top: 10px;
  background: #fff;
  width: 100%;
  border-radius: 10px;
}
.life .life-box .item .top img {
  width: 100%;
  height: 150px;
}
.life .life-box .item .bottom {
  padding: 10px;
  font-size: 12px;
  color: #333;
}
.life .life-box .item .bottom .title {
  text-align: left;
}
.life .life-box .item .bottom .total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  padding-top: 6px;
}
.life .life-box .item .bottom .total .num {
  color: red;
  font-weight: normal;
  text-align: right;
}
</style>
